
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import DataContext, {
    Bases,
    DataContextModel,
    MesaAyuda,
    Operacion,
    Trabajador,
    VotacionResultado,
} from './data-context';

const DataContextProvider: React.FC = (props) => {


    const history = useHistory();


    // const url_api = "http://127.0.0.1:8000";
     const url_api = "https://voto-cafae-api.dirislimasur.gob.pe";

    // const [trabajador, setTrabajador] = useState<Trabajador>({} as Trabajador);

    const agregarTrabajador = (id: number,dni: string, nombre_completo: string, cargo: string , token: string, is_admin: Boolean) => {
        const addTrabajador: Trabajador = { id, dni, nombre_completo, cargo, token, is_admin };
        // setTrabajador(addTrabajador);
        localStorage.setItem("@diris_ls-token", token);
        localStorage.setItem("@diris_ls-apellidos_nombre", nombre_completo);
        localStorage.setItem("@diris_ls-dni", dni);
        localStorage.setItem("@diris_ls-is_admin", is_admin.toString());
        localStorage.setItem("@diris_ls-id", id.toString());
    }


    const Logout = async () => {
        const result = await fetch(url_api + '/logout-api/', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + getToken()
            }
        });
        localStorage.setItem("@diris_ls-token", "");
        localStorage.setItem("@diris_ls-apellidos_nombre", "");
        localStorage.setItem("@diris_ls-dni", "");
        localStorage.setItem("@diris_ls-is_admin", "");
        localStorage.setItem("@diris_ls-id", "");
        history.replace('/main');
    }


    const [operaciones, setOperaciones] = useState<Operacion[]>([]);


    const obtenerOperaciones = async () => {
        const result = await fetch(url_api + '/api/operacion/', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + getToken()
            }
        });

        if (result.status === 200) {
            const data = await result.json();
            setOperaciones(data.results);
        } else {
            salirSistema();
        }

    }

 

    const [votacionResultado, setVotacionResultado] = useState<VotacionResultado>({} as VotacionResultado);

    const obtenerVotacionResultado = async () => {
        const result = await fetch(url_api + '/api/votacion-resultados', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'token ' + getToken()
            }
        });

        if (result.status === 200) {
            const data = await result.json();
            setVotacionResultado(data);
        } else {
            salirSistema();
        }

    }

    const [mesaAyuda, setMesaAyuda] = useState<MesaAyuda>({} as MesaAyuda);

    const obtenerMesaAyuda = async () => {
        const result = await fetch(url_api + '/api/mesa-ayuda/', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (result.status === 200) {
            const data = await result.json();
            setMesaAyuda(data.results[0]);
        } else {
            salirSistema();
        }
    }

    const [bases, setBases] = useState<Bases>({} as Bases);

    const obtenerBases = async () => {
        const result = await fetch(url_api + '/api/bases/', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (result.status === 200) {
            const data = await result.json();
            setBases(data.results[0]);
        } else {
            salirSistema();
        }
    }

    const salirSistema = () => {
        Logout();

    }

    const getToken = () => {
        return localStorage.getItem("@diris_ls-token");
    }

    const getApellidosNombres = () => {
        return localStorage.getItem("@diris_ls-apellidos_nombre");
    }

    const getDni = () => {
        return localStorage.getItem("@diris_ls-dni");
    }
    const getIsAdmin = () => {
        return localStorage.getItem("@diris_ls-is_admin");
    }

    const getId = () => {
        return localStorage.getItem("@diris_ls-id");
    }


    const dataContext: DataContextModel = {
        operaciones,
        obtenerOperaciones,
        agregarTrabajador,
        url_api,
        votacionResultado,
        obtenerVotacionResultado,
        mesaAyuda,
        obtenerMesaAyuda,
        salirSistema,
        getToken,
        getApellidosNombres,
        getDni,
        getIsAdmin,
        getId,
        obtenerBases,
        bases,
    };

    return (
        <DataContext.Provider value={dataContext}>
            {props.children}
        </DataContext.Provider>
    );
}

export default DataContextProvider