import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonProgressBar, IonRow, IonTitle, IonToast, IonToolbar} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react'
import DataContext from '../../data/data-context';
import './Main.css';
import { cellularOutline, fileTrayStackedOutline, phonePortraitSharp } from 'ionicons/icons';

const Main: React.FC = () => {

    type toastObject = {
        text: string;
        type: string;
    };

    const dataContext = useContext(DataContext);
    const [toast, setToast] = useState<toastObject | undefined>(undefined);
    const [spinner, setSpinner] = useState<boolean>(false);

    const digitoVerificacionInput = useRef<HTMLIonInputElement>(null);
    const history = useHistory();
    const [spinnerPage, setSpinnerPage] = useState<boolean>(true);

    const [numeroDocumentoState, setNumeroDocumentoState] = useState<string>("");
    const [fechaExpedicionState, setFechaExpedicionState] = useState<string>("");


    const getAPI = async (dni: string, digito_verificacion: string,  fecha_expedicion: string) => {

        // console.log(fecha_expedicion);
        const [year, month, day] = fecha_expedicion.split('-');
        const fechaFormateada = `${day}/${month}/${year}`; // Formatea la fecha
        // Envía la fecha formateada al backend
        console.log("Fecha enviada al backend:", fechaFormateada);
        await fetch(dataContext.url_api + '/api/setup/empleado-existe/?numero_documento=' + dni + "&digito_verificacion=" + digito_verificacion + "&fecha_expedicion=" + fechaFormateada)
            .then(response => response.json())
            .catch(error => {
                const toastInstance: toastObject = {
                    text: "Ups, datos incorrectos",
                    type: "danger",
                }
                setToast(toastInstance);
                setSpinner(false)
            })
            .then(response => {

                if (response.status === "true") {

                    dataContext.agregarTrabajador(
                        response.empleado.id, 
                        response.empleado.dni, 
                        response.empleado.nombre_completo, 
                        response.empleado.cargo,
                        response.empleado.token, 
                        response.empleado.is_admin);

                    history.replace('/all_operaciones');

                } else {
                    const toastInstance: toastObject = {
                        text: "Ups, datos incorrectos",
                        type: "danger",
                    }
                    setToast(toastInstance);
                }
                setSpinner(false)

            });
    }

    const submitForm = (e: any) => {
        e.preventDefault();

        const digito_verificacion = digitoVerificacionInput.current?.value as string;

        setSpinner(true)

        if (numeroDocumentoState.length === 8 && digito_verificacion && fechaExpedicionState) {
            getAPI(numeroDocumentoState, digito_verificacion, fechaExpedicionState);
        } else {
            const toastInstance: toastObject = {
                text: "Ups, datos incorrectos",
                type: "danger",
            }
            setToast(toastInstance);
            setSpinner(false)

        }
    }

    const cleanToast = () => {
        const toastInstance: toastObject = {
            text: "",
            type: "medium",
        }
        setToast(toastInstance);
    }


    const cargandoPaginaInicial = () => {
        window.setTimeout(() => {
            setSpinnerPage(false);
        }, 500)
    }




    const handleChangeNumeroDocumento = () => {
        const input_value = numeroDocumentoState.replace(/\D+/g, "");
        setNumeroDocumentoState(input_value);

    };


    useEffect(() => {
        cargandoPaginaInicial();
        dataContext.obtenerMesaAyuda();
        dataContext.obtenerBases();
    }, []);

    useEffect(() => {
        handleChangeNumeroDocumento();
    }, [numeroDocumentoState]);




    return (
        <div>

            <IonToast isOpen={!!toast?.text}
                message={toast?.text}
                duration={1400}
                color={toast?.type}
                position='middle'
                onDidDismiss={() => cleanToast()} />

            <IonPage className="page-main">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="ion-text-center">
                            <img src="/assets/images/logo_diris.jpg" alt="DIRIS" />
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>


                {spinnerPage ?
                    <IonContent className="ion-text-center background-image">
                        <IonProgressBar color="primary" value={0.5} type="indeterminate"></IonProgressBar>
                        <IonIcon icon={cellularOutline} size="large"></IonIcon>
                    </IonContent>
                    : <IonContent className="background-image">

                        <IonRow>
                            <IonCol size="12" size-md="4">
                            </IonCol>
                            <IonCol size="12" size-md="4">

                                <IonCard className="card-main">
                                    <IonCardHeader>
                                        <IonCardTitle className="ion-text-center" >
                                          {/*  <h1 className="title-page">
                                                SUB CAFAE<br></br> </h1>*/}
                                                <img src="/assets/images/titulo_subcafae.png"  />
                                            <div className="contend-titulo">
                                                <strong className="titulo-main">
                                                    ELECCIONES 2024
                                                </strong>
                                            </div>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent >
                                        <form method="post" onSubmit={submitForm}>
                                            <small className="sub-titulo-main">Ingrese su datos personales: </small>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem>
                                                            <IonLabel position='floating'>
                                                                DNI
                                                            </IonLabel>
                                                            <IonInput
                                                                // clearInput
                                                                value={numeroDocumentoState}
                                                                onIonChange={e => setNumeroDocumentoState(e.detail.value!)}
                                                                type="text"
                                                                placeholder="Ingrese el número de documento"
                                                                required={true}
                                                                minlength={8}
                                                                maxlength={8}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem>
                                                            <IonLabel position='floating'>
                                                                Dígito de Verificación DNI (CUI)
                                                            </IonLabel>
                                                            <IonInput ref={digitoVerificacionInput} type="number"
                                                                required={true}
                                                                minlength={2}
                                                                maxlength={2}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonItem>
    <IonLabel position="floating" style={{ fontSize: '14px', marginBottom: '8px' }}> {/* Ajusta el tamaño y el margen según sea necesario */}
        Fecha de emisión
    </IonLabel>
    <IonInput
        type="date"
        value={fechaExpedicionState}
        onIonChange={(e) => setFechaExpedicionState(e.detail.value!)}
    />
</IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>

                                                    <IonCol className='ion-text-center ion-margin-top'>
                                                        {spinner ? <IonProgressBar type="indeterminate"></IonProgressBar> :
                                                            <IonButton expand='block' color="primary" type="submit">
                                                                Validar
                                                            </IonButton>
                                                        }
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>


                                                  {/*   <IonCol className='ion-text-center ion-margin-top' size="12" size-md="6">

                                                        <IonChip
                                                            onClick={() => window.open("https://api.whatsapp.com/send?phone=999".replace('999', '051' + dataContext.mesaAyuda.numero), "_blank")}>
                                                            <IonAvatar>
                                                                {dataContext.mesaAyuda ?  <img src={dataContext.mesaAyuda.imagen} /> : "-"}
                                                               
                                                            </IonAvatar>
                                                            <IonLabel>
                                                                {dataContext.mesaAyuda ? dataContext.mesaAyuda.mensaje ? dataContext.mesaAyuda.mensaje : "Mesa de ayuda" : "-"}
                                                               </IonLabel>
                                                            <IonIcon icon={phonePortraitSharp} />
                                                        </IonChip>

                                                    </IonCol>

                                                    <IonCol className='ion-text-center ion-margin-top' size="12" size-md="6">
                                                        <IonChip
                                                            color="danger"
                                                            onClick={() => window.open(dataContext.bases.archivo, "_blank")}>
                                                            <IonAvatar>
                                                                <img src="https://cdn-icons-png.flaticon.com/512/203/203029.png" />
                                                            </IonAvatar>
                                                            <IonLabel>
                                                                {dataContext.bases ?
                                                                    !!dataContext.bases.nombre ? dataContext.bases.nombre : "Mesa de ayuda" : " -- "}
                                                            </IonLabel>
                                                            <IonIcon icon={fileTrayStackedOutline} />
                                                        </IonChip>
                                                    </IonCol>

                                                    <IonCol className='ion-text-center ion-margin-top' size="12" size-md="12">
                                                        <a href="#"
                                                            onClick={() => window.open("https://play.google.com/store/apps/details?id=cafae.dirislimasur.com", "_blank")}
                                                        ><img src="https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png" alt="DIRIS" width="200" /></a>
                                                    </IonCol>*/}

                                                </IonRow>
                                            </IonGrid>
                                        </form>
                                    </IonCardContent>
                                </IonCard>

                            </IonCol>
                            <IonCol size="12" size-md="4">
                            </IonCol>
                        </IonRow>


                    </IonContent>
                }
            </IonPage>
        </div>
    )
}
export default Main